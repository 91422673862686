<template>
    <div>
        <LocationsList
            v-bind="{
                handleLocationClick: handleDistrictNodeClick,
                locations: districtsTree,
                isLoading,
            }"
        />
    </div>
</template>
<script>
import TreeView from '../../widgets/MenuPanel/ui/TreeView'
import LocationsList from '../../features/general/LocationsList/ui/LocationsList.vue'

import loadSource from '../../entities/Map/general/model/loadSource'
import handleDistrictNodeClick from './methods/handleDistrictNodeClick'
import {
    setDistrictsListGasPlans,
    setDistrictsListProposals,
} from './methods/setDistrictsList'

import { mapState } from 'vuex'
import isAllowMenuChange from '../../shared/lib/isAllowMenuChange'
import { appModuleIds, stepsListIndexes } from '../../shared/constants'

export default {
    name: 'DistrictsView',
    props: {},
    components: {
        // TreeView,
        LocationsList,
    },
    data() {
        return {
            isLoading: false,
        }
    },
    async mounted() {
        await this.setListActions()
    },
    watch: {
        chosenRegionId: {
            async handler(newChosenRegionId) {
                console.log('newChosenRegionId chached', newChosenRegionId)

                if (!newChosenRegionId) return

                await this.setListActions()
            },
            immediate: true,
        },
        focusedRegionNumber: {
            async handler() {
                // if(this.$state.map.zoom < ZOOM_HIERARCHY_MIN_LEVEL[stepsListIndexes.districtsStepIndex]) return
                await this.isAllowMenuChange(
                    async () => await this.$dispatch('setDistrictsStats', this)
                )
                await this.isAllowMenuChange(
                    async () =>
                        await this.$dispatch('setDistrictsPlansCount', this)
                )

                await this.isAllowMenuChange(this.setDistrictsList)
            },
            immediate: true,
        },
        async chosenAppModule() {
            await this.setListActions()
        },
        async activeYear() {
            await this.setListActions()
        },
    },
    methods: {
        loadSource,
        handleDistrictNodeClick,
        isAllowMenuChange,
        setDistrictsListGasPlans,
        setDistrictsListProposals,

        async setListActions() {
            await this.$dispatch('setDistrictsStats', this)
            await this.$dispatch('setDistrictsPlansCount', this)
            await this.setDistrictsList()
        },
        async setDistrictsList() {
            this.isLoading = true

            const features = await this.getAllDistricts()
            if (!features) return

            const sortedDistricts = features.sort((a, b) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            })
            switch (this.chosenAppModule?.id) {
                case appModuleIds.gasification:
                    this.setDistrictsListProposals(sortedDistricts)
                    break
                case appModuleIds.spg:
                    this.setDistrictsListProposals(sortedDistricts)
                    break
                case appModuleIds.gasificationPlan:
                    this.setDistrictsListGasPlans(sortedDistricts)
                    break
                default:
                    this.setDistrictsListProposals(sortedDistricts)
                    break
            }

            this.isLoading = false
        },
        async getAllDistricts() {
            return await this.loadSource(
                `/district/${
                    this.$state.view.regionsView.chosenRegion.id ||
                    this.$state.focusedRegionNumber
                }`
            )
        },
    },
    computed: {
        ...mapState({
            districtsTree: state => state.view.treeLists.districts,
            chosenRegionId: state => state.view.regionsView.chosenRegion.id,
            focusedRegionNumber: state => state.focusedRegionNumber,
            focusedDistrictNumber: state => state.focusedDistrictNumber,
            districtsProposalsStats: state =>
                state.view.statsProposals.districts,
            districtsGasificationPlans: state =>
                state.view.gasificationPlan.districts,
            chosenAppModule: state => state.chosenAppModule,
            activeYear: state => state.view.gasificationPlan.activeYear,
            stepActiveId: state => state.view.stepActiveId,
        }),
    },
}
</script>

<style src="../DistrictsView/index.styl" lang="stylus" module></style>
