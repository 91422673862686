import { TreeList } from '@/widgets/MenuPanel/ui/TreeView/methods/TreeList'
import { planViewTitles } from '@/shared/constants'

export const setDistrictsListProposals = function (this: any, sortedDistricts) {
    this.$state.view.treeLists.districts = sortedDistricts.map(district => {
        // console.log('district stat', district)
        const envelopeBbox = district?.bbox

        return new TreeList(
            district.name,
            null,
            district.centroid,
            district.id,
            this.districtsProposalsStats?.[district.id]?.all_proposal_count,
            envelopeBbox
        )
    })
}
export const setDistrictsListGasPlans = function (this: any, sortedDistricts) {
    this.$state.view.treeLists.districts = sortedDistricts.map(district => {
        const envelopeBbox = district?.bbox

        return new TreeList(
            district.name,
            null,
            district.centroid,
            district.id,
            this.districtsGasificationPlans?.[district.id]?.count,
            envelopeBbox
        )
    })
}
